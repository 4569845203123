import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Title from '../src/components/Title/Title';
import Page from '../src/layout/page/Page';

const Custom404 = () => {
  return (
    <Page>
      <Title>404 - Page Not Found</Title>
    </Page>
  );
};

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'error'])),
      notFound: true,
    },
  };
};

export default Custom404;
